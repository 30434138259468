import './RecordItem.css';
import defaultImg from 'assets/icon/128_128.svg';


import {Button} from "antd";
import { DownloadOutlined, CheckOutlined } from '@ant-design/icons';
import {useEffect, useState} from "react";

import {Record_Get} from "../../../../service/api";
import {isJsonString} from "../../../../util/isJsonString";

export default function RecordItem(props: {
    id: string;	// 主键
    create_time: string;	// 创建时间
    user_id: string;	// 用户id
    room_id: string;	// 房间id
    version: string;	//版本
    onDownLoadSuccess: Function //完成下载后的回调函数
    client_data: string | null; // 客户端信息
    checked: boolean	// 是否已读
}) {

    const [loading, setLoading] = useState(false);
    const [downLoadStatue, setDownLoadStatue] = useState(false);
    const [buttonIcon, setButtonIcon] = useState(<DownloadOutlined/>);
    const [clientData, setClientData] = useState<{
        language?: string,
        // 浏览器
        browser?: string,
        // os
        os?: string,
        // 屏幕尺寸
        screenSize?: {
            width: number,
            height: number
        },
        // 窗口尺寸
        windowSize?: {
            width: number,
            height: number
        },
        // ip信息
        ip?: {
            cip: string,
            cid: string,
            cname: string
        }
    }>({});

    const [editorUrl, setEditorUrl] = useState('https://www.baidu.com');

    const [imgUrl, setImgUrl] = useState<string | null>(null);

    useEffect(() => {

        // 解析客户端信息
        if (props.client_data) {

            if (isJsonString(props.client_data)) {
                setClientData(JSON.parse(props.client_data) as any);
                console.log(JSON.parse(props.client_data));
            } else {
                setClientData({});
            }

        }

    }, []);

    useEffect(() => {
        setImgUrl('https://graphorigin-online-3d-editor-backend-public.oss-cn-beijing.aliyuncs.com/room/image/'+ props.room_id +'.png')
    }, [props.room_id])

    useEffect(() => {
        setEditorUrl('https://3d.track.graphorigin.com/editor/' + props.version.replace('v', ''));
    }, [props.version])

    const download = () => {
        if(downLoadStatue) return;
        setLoading(true);
        Record_Get(props.id).then(res => {
            props.onDownLoadSuccess && props.onDownLoadSuccess({
                id: props.id, 
                screen: clientData.screenSize ?
                        (clientData.screenSize.width + '*' + clientData.screenSize.height) :
                        '未知',
                ip: clientData?.ip?.cip,
                postalCode: clientData?.ip?.cid,
                area: clientData?.ip?.cname,
                version: props.version,
                create_time: props.create_time.replace('T', ' '),
                room_id: props.room_id,
                user_id: props.user_id,
                imgUrl: imgUrl,
                data: res
            });

            setLoading(false);
            setButtonIcon(<CheckOutlined/>);
            setDownLoadStatue(true);
        })
    }

    return (
        <div className={'record-item'} style={{opacity: props.checked ? 0.4 : 1}}>

            <img alt={'img'} src={ imgUrl || defaultImg}/>

            <div className={'record-item-right'}>

                <div className={'record-item-right-info-left'}>

                    <span className={'record-item-id'}>ID：{props.id}</span>
                    <br/>

                    <span>屏幕：
                        {
                            clientData.screenSize ?
                            (clientData.screenSize.width + '*' + clientData.screenSize.height) :
                                '未知'
                        }
                    </span>
                    <br/>
                    <span>ip: {clientData?.ip?.cip || '未知'}</span>
                    <br/>
                    <span>邮编: {clientData?.ip?.cid || '未知'}</span>
                    <br/>
                    <span>地区: {clientData?.ip?.cname || '未知'}</span>
                    <br/>

                    <span className={'record-item-time'}>{props.create_time.replace('T', ' ')}</span>

                </div>

                <div className={'record-item-right-info-right'}>

                    <div className={'record-item-info-right-urId'}>
                        <span>房间号：{props.room_id}</span>
                        <br/>
                        <span>用户ID：{props.user_id}</span>
                    </div>

                    <div>
                        <Button
                            className={'record-item-download-icon'}
                            type={'primary'}
                            shape={"circle"}
                            size={"small"}
                            loading={loading}
                            icon={<DownloadOutlined/>}
                            onClick={download}
                        />
                        <a href={editorUrl} target={'_blank'}>{props.version}</a>
                    </div>


                </div>


            </div>

        </div>
    )

}
