import './ModelReview.css';

export default function ModelReview() {

    return (
        <div id={'model-review'}>


        </div>
    )
}