import './App.css';
import {useRoutes} from "react-router-dom";
import routes from "config/routes.config";
import TopBar from "./routes/TopBar/TopBar";
import React from "react";
import 'antd/dist/antd.min.css';

export default function App() {
    const element = useRoutes(routes);

    return (
        <>
            <TopBar />
            {element}
        </>
    )
}
