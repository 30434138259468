import Online from "../routes/Module/Online/Online";
import React from "react";
import {RouteObject} from "react-router-dom";
import Detail from "../routes/Detail/Detail";
import Matomo from "../routes/Module/Matomo/Matomo";
import AcTracePanel from "../routes/Module/3DAcTrace/3DAcTrace";
import ModelReview from "../routes/Module/ModelReview/ModelReview";

let routes: RouteObject[] = [
    {
        path: "/",
        element: <Detail />,
        children: [
            {
                path: "/online",
                element: <Online />,
                children: [
                    {
                        index: true,
                        path: 'main',
                        element: <iframe title={'main'} style={{height: '100%', width: '100%'}} src={'https://graphorigin.com'} />
                    },{
                        path: 'beta',
                        element: <iframe title={'beta'} style={{height: '100%', width: '100%'}} src={'https://beta.graphorigin.com'} />
                    },{
                        path: 'dev',
                        element: <iframe title={'dev'} style={{height: '100%', width: '100%'}} src={'https://dev.graphorigin.com'} />
                    }
                ]
            },
            {
                path: "/3DAcTrace",
                element: <AcTracePanel />
            },
            {
                path: "/matomo",
                element: <Matomo />
            },
            {
                path: "/model",
                element: <ModelReview />
            }
        ]
    },{
        path: "*",
        element: <div />
    }
]

export default routes;