import axios from 'axios';

const getApi = (api = '') => api;

/**
 * 获取响应中指定响应头的值
 * @param XhrResponse response
 * @param key 响应头的key
 * @returns {*|string}
 */
const getXhrResponseHeader = function (XhrResponse: any, key: any) {
    let value;

    if (XhrResponse.getResponseHeader) {
        value = XhrResponse.getResponseHeader(key);
    } else if (XhrResponse.headers && XhrResponse.headers[key.toLowerCase()]) { // key值在headers里会被转为小写
        value = XhrResponse.headers[key.toLowerCase()];
    }
    return value;
};

// 添加请求拦截器
axios.interceptors.request.use((config) => {

    if (!config.timeout || config.timeout <= 0) {
        config.timeout = 120000;
    }

    if (config.headers){
        config.headers['X-Requested-With'] = 'XMLHttpRequest';
        config.headers['REQUEST_HEADER'] = 'binary-http-client-header';
    }

    return config;
}, (error) => {
    // 对请求错误做些什么
    Promise.reject(error).then(r => console.log(r));
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {

    return response.data;

}, (error) => {

    return Promise.reject(error)

});

/**
 * 缓存请求，同一请求的拦截不再向后台发送
 * @param config
 */
const ajax = (config: {}) => {
    return axios(config).then((response) => {
        const result = response.data;

        const errorMsg = getXhrResponseHeader(response, 'errorMsg');

        if (errorMsg) {
            console.warn('Graphorigin-api - ', errorMsg);
            result.errorMsg = errorMsg;
        }
        return result;
    }, (err) => {
        throw err;
    });
};

export const get = (url: string, data: object = {}, config: any = {}) => {

    config = config || {};
    if (!config.baseURL) config.baseURL = getApi();

    config.method = 'get';
    config.url = url;
    config.params = data;

    return ajax(config);

};

export const post = (url: string, data: {}, config: any = {}) => {

    config = config || {};
    if (!config.baseURL) config.baseURL = getApi();

    config.method = 'post';
    config.url = url;
    config.data = data;

    return ajax(config);

};
