import './Detail.css';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
export default function Detail() {

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.pathname === '/'){
            navigate('3DAcTrace');
        }
    }, [location.pathname])

    return (
        <div id={'detail'}>
            <Outlet />
        </div>
    )
}