import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {useEffect} from "react";
import './Online.css';

export default function Online() {

    const navigate = useNavigate();

    const location = useLocation();

    useEffect(() => {
        navigate('main')
    }, [])

    useEffect(() => {
        if (location.pathname === '/online'){
            navigate('main')
        }
    }, [location.pathname])

    return (
        <div id={'online'}>
            <div className={'online-left-bar'}>
                <ul>
                    <li className={'online-left-bar-li'} onClick={() => {navigate('main')}}>正式版本</li>
                    <li className={'online-left-bar-li'} onClick={() => {navigate('beta')}}>测试版本</li>
                    <li className={'online-left-bar-li'} onClick={() => {navigate('dev')}}>开发版本</li>
                </ul>
            </div>
            <Outlet />
        </div>
    )
}