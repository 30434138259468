import {post} from "./request";
import {apiPath} from "./apiPath";

// 搜索所有记录
export const Record_Search = (data: {
    id?: string,
    createTime?: Date,
    userId?: string,
    roomId?: string,
    version?: string,
    checked?: boolean
} = {}) => {
    return post(apiPath.Record_Search, data);
}

export type TRecordItem = {
    checked: number,
    clientData: string,
    createTime: string,
    id: string,
    roomId: string,
    userId: string,
    version: string,
}

export type TRecord_SearchResult = {
    current: number,
    records: TRecordItem[],
    size: number,
    total: number
}


// 搜索单条记录
export const Record_Get = (id: string) => {
    return post(`${apiPath.Record_Get}/${id}`, {})
}