import './TopBar.css';
import {Link} from "react-router-dom";
const config: {name: string, path: string}[] = [
    {
        name: 'Online',
        path: '/online'
    },

    {
        name: 'Matomo',
        path: '/matomo'
    },

    {
        name: '回放',
        path: '/3DAcTrace'
    },

    {
        name: '模型审核',
        path: '/model'
    }
]
export default function TopBar(){

    return (
        <div id={'top-bar'}>
            <div className={'top-bar-left'}>
                图形起源后台管理平台
            </div>

            <div className={'top-bar-right'}>
                {
                    config.map(item => <Link key={item.path} className={'top-bar-right-link'} to={item.path}>{item.name}</Link>)
                }
            </div>
        </div>
    )
}