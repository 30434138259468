import './3DAcTrace.css';
import RecordItem from "./RecordItem/RecordItem";
import {useEffect, useState} from "react";
import {Record_Search, TRecord_SearchResult, TRecordItem} from "../../../service/api";
export default function AcTracePanel() {

    const [list, setList] = useState([] as TRecordItem[]);

    useEffect(() => {
        Record_Search().then((res:TRecord_SearchResult) => {
            console.log(res);
            setList(res.records);
        })
    }, [])

    // 不存储下载完成列表的具体data
    const [successDownLoadList, setSuccessDownLoadList] = useState<Array<object>>([]);
    // 存储下载完成列表的具体信息
    const [successDownLoadListData, setSuccessDownLoadListData] = useState<Array<object>>([]);
    const handleDownLoadSuccess = (data: any) => {
        // 向插件发送信息
        window.postMessage({type: 'web-send', data: data});
        
        let finalArr = JSON.parse(JSON.stringify(successDownLoadList));
        finalArr.push({id: data.id})
        setSuccessDownLoadList(finalArr);

        finalArr = successDownLoadListData;
        finalArr.push(data);
        setSuccessDownLoadListData(finalArr);
    }


    useEffect(() => {
        // console.log('successDownLoadList', successDownLoadList);

    }, [successDownLoadList])

    return (
        <div id={'AcTrace-panel'}>

            {
                list.length > 0 ?
                    list.map(item =>
                        <RecordItem
                            key={item.id}
                            id={item.id}
                            version={item.version}
                            room_id={item.roomId}
                            create_time={item.createTime}
                            checked={item.checked === 1}
                            user_id={item.userId}
                            client_data={decodeURI(item.clientData)}
                            onDownLoadSuccess={handleDownLoadSuccess}
                        />
                    )
                    :
                    [ 'skeleton-000', 'skeleton-001', 'skeleton-002'].map(item =>
                        <RecordItem
                            key={item}
                            id={item + '6a56fa56sdf641f65saf1656g56as6dg6'}
                            version={'v1.0.0'}
                            room_id={'我是room_id'}
                            create_time={'1999-07-11T00:00:00'}
                            checked={false}
                            user_id={'我是user_id'}
                            client_data={null}
                            onDownLoadSuccess={handleDownLoadSuccess}
                        />
                    )

            }

        </div>
    )
}









// import './3DAcTrace.css';

// import { useEffect, useState } from 'react';


// export default function AcTracePanel() {

//     useEffect(() => {
//         console.log('3DAcTrace');

//         window.addEventListener("message", message)

//         return () => {
//             window.removeEventListener("message", message);
//         }


//     }, [])

//     function message(ev: any){
//         if(ev.data.type !== 'content-send') return;
//         console.log(ev.data)
//     }

//     const [count, setCount] = useState(0);

//     const clickDiv = () => {

//         window.postMessage({type: 'web-send', data: count});

//         setCount(count + 1);
//     }

//     return (
//         <div id={'AcTrace-panel'}>
//             <div className='test'  style={{width: '200px', height: '200px', background: '#000'}} onClick={clickDiv}></div>
//         </div>
//     )
// }